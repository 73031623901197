import React, { useEffect, useRef, useState } from "react";

import "../../css/DashBoard.css";
import RichBox from "./smallComponents/RichBoxComponent";
import { handleCount } from '../../helpers/validation';
import { MapSecondsToTime, makeId, requestWithAxios } from "../../helpers/func";
import Swal from "sweetalert2";
import DifficultLevel from "./smallComponents/DifficultLevel";
import { BASE_URL } from "../../variables/api";
import $ from "jquery"
import axios from "axios";
import "./Rubrics.css"
import LearningStandards from "./smallComponents/LearningStandards";
const GradingColors = ["#EC1A1A", "#EDFF2D", "#169E0A", "#0716e7"];
// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent


const Essay = (props: any) => {
  const [state, setState] = useState<any>({
    id: makeId(10),
    type: "EssayComponent",
    show_objectives: false,
    objectives: "",
    feedback: "",
    mode: "",
    total_mark: 0,
    score: "0",
    header: '',
    answer_place_holder: '',
    stdanswer: '<p></p>',
    stdanswer_txt: '',
    wordlimit: 100000,
    allow_over_limit: false,
    count: 0,
    overcount: false,
    show_word_count: false,
    show_word_limit: false,
    headertxt: '',
    required: true,
    difficult: "Basic Level",
    cognitive: "Remembering",
    alert: null,
    calibration: "",
    spenttime: 0,
    rubricsmode: "Analytical",
  })

  let count = useRef<any>();
  const [spenttime, setSpentTime] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(null);
  useEffect(() => {
    if (props.quest != undefined) {

      let Question = { ...props.quest };


      if (Question.mode == "examMode") {

        axios.get(`${BASE_URL}/student/getspenttime.php?studentexamquestion_id=${Question.id}`).then((response) => {
          setSpentTime(response.data.data);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)
        }).catch((err) => {
          setSpentTime(0);
          const intervalId = setInterval(() => {
            setSpentTime((prev: any) => ++prev);
            // props.onSaveQuestion({ ...state })
          }, 1000);
          setIntervalId(intervalId)

        });
        setState({ ...Question })

      } else if (Question.mode == "editingMode") {
        let cal_array: any = [];
        let calibrationObject = Question.calibration
        if (typeof Question.calibration == "string") {
          try {
            calibrationObject = JSON.parse(Question.calibration)
          } catch (error) {
            calibrationObject = Question.calibration
          }
        }
        if (Question.calibration !== undefined && Question.calibration !== null && Question.calibration !== "" && Question.rubricsmode != "Holistic") {
          let Analytical = calibrationObject?.Analytical ?? calibrationObject
         
          let splitted_calibration = Analytical?.split(",,");
          splitted_calibration.forEach((cal: any) => {
            // Regular expressions to extract numbers       
            var scoreRegex = /\b\d+\b/;
            var testRegex = /if the student's answer\s(.+)/;
            // Extracting the numbers
            var scoreMatch = cal.match(scoreRegex);
            var testMatch = cal.match(testRegex);
            // Converting matches to integers
            var total = scoreMatch ? parseInt(scoreMatch[0]) : null;
            var text = testMatch ? testMatch[1] : null;
            if (total != null && text != null) {

              let cal_obj = { total: total, text: text }
              cal_array.push(cal_obj)
            }
          });
        } else {
          if (props.etype == "capstone" && Question.rubricsmode == "Analytical") {
            for (let index = 4; index > 0; index--) {
              if (index == 1) continue;
              cal_array.push({ total: index, text: "" });
            }
          }
        }
        if(cal_array.length ==0 ){
          for (let index = Question.total_mark; index > 0; index--) {
            cal_array.push({ total: index, text: "" });

          }
        }
        setState({ ...Question, calibrationArray: [...cal_array], calibration: calibrationObject })
      } else {
        setState({ ...Question })
      }
    } else {
      let cal_array: any = [];
      let total_mark = 0
      if (state.calibration !== undefined && state.calibration !== null && state.calibration !== "") {
        let splitted_calibration = state.calibration?.Analytical?.split(",,");
        splitted_calibration.forEach((cal: any) => {
          // Regular expressions to extract numbers       
          var scoreRegex = /\b\d+\b/;
          var testRegex = /if the student's answer\s(.+)/;
          // Extracting the numbers
          var scoreMatch = cal.match(scoreRegex);
          var testMatch = cal.match(testRegex);
          // Converting matches to integers
          var total = scoreMatch ? parseInt(scoreMatch[0]) : null;
          var text = testMatch ? testMatch[1] : null;
          if (total != null && text != null) {

            let cal_obj = { total: total, text: text }
            cal_array.push(cal_obj)
          }
        });
      } else {
        if (props.etype == "capstone") {
          total_mark = 4
          for (let index = 4; index > 0; index--) {
            if (index == 1) continue;
            cal_array.push({ total: index, text: "" });

          }
        }

      }
      setState((prev: any) => ({ ...prev, calibrationArray: [...cal_array], total_mark: total_mark, mode: "editingMode", all_targets: props.course_standards_targets }))

      Swal.close()
    }
  }, []);

  useEffect(() => {
    return () => {
      if (state.mode == "examMode" && state.studentexam_id !== undefined && state.studentexam_id != null) {
        clearInterval(intervalId)
        requestWithAxios(
          BASE_URL + "student/savespenttime.php",
          { spenttime: count.current.value, "studentexamquestion_id": state.id }
        );
      }
    }
  }, [intervalId])


  const onSaveQuestion = (updatedState: any) => {
    {
      let require = handleCount(updatedState.header);
      if (require) {
        Swal.fire({
          icon: 'error',
          text: ' Question Header is required',
        }).then(() => {

        })
      } else if ((updatedState.total_mark.length == 0) || (state.total_mark == ".")) {
        Swal.fire({
          icon: 'error',
          text: ' Total mark is required',
        }).then(() => {

        })
      } else {
        if (updatedState.mode == "editingMode") {

          let calibration = "";
          if (updatedState.calibrationArray?.length > 0 && updatedState.calibration?.Holistic) {
            updatedState.rubricsmode = "Both"
            for (let index = updatedState.total_mark; index > 0; index--) {

              if (props.etype == "capstone" && index == 1) continue;

              let calibration_text = updatedState.calibrationArray.find((cal: any) => cal.total == index)?.text ?? "";
              calibration_text = calibration_text.replace(/,,/g, ',')
              calibration += "set the score to " + index + " if the student's answer " + calibration_text + " ,, ";
            }
            if (props.etype == "capstone") {

              calibration += " else set score to 1";
            } else {
              calibration += " else set score to 0";
            }
            updatedState.calibration.Analytical = calibration;

          } else if (updatedState.calibrationArray?.length == 0 && updatedState.calibration?.Holistic) {
            updatedState.rubricsmode = "Holistic"
            updatedState.calibration.Analytical = calibration;


          } else {
            updatedState.rubricsmode = "Analytical"
            for (let index = updatedState.total_mark; index > 0; index--) {

              if (props.etype == "capstone" && index == 1) continue;

              let calibration_text = updatedState.calibrationArray.find((cal: any) => cal.total == index)?.text ?? "";
              calibration_text = calibration_text.replace(/,,/g, ',')
              calibration += "set the score to " + index + " if the student's answer " + calibration_text + " ,, ";
            }
            if (props.etype == "capstone") {

              calibration += " else set score to 1";
            } else {
              calibration += " else set score to 0";
            }
            let calibrationObj = {
              Analytical: calibration,
              Holistic: ""
            }
            updatedState.calibration = calibrationObj;

          }

        }


        if (state.mode == "examMode") {

          let count = handleStudentAnsCount(updatedState.stdanswer);
          if (count > updatedState.wordlimit) {
            setState((prev: any) => ({ ...prev, count: count, overcount: true, stdanswer: updatedState.stdanswer }))
          } else {
            setState((prev: any) => ({ ...prev, count: count, overcount: false, stdanswer: updatedState.stdanswer }))
          }
          updatedState.count = count;
        }
        if (state.mode == "examMode" && state.studentexam_id != undefined && state.studentexam_id != null) {

          props.onSaveQuestion(updatedState);

          const timeOutId = setTimeout(() => {

            requestWithAxios(
              BASE_URL + "student/saveq.php",
              updatedState
            );
          }, 1000);
          return () => clearTimeout(timeOutId);
        } else {
          props.onSaveQuestion(updatedState);

        }


      }
    }
  }

  let claibrationArray: any = [];
  const onHandleCount = () => {
    let require = handleCount(state.header);

    setState((prev: any) => ({ ...prev, required: require }))

  }
  // setHeader = (contents: any) => {
  //   header = contents;
  // };


  const handleStudentAnsCount = (a: any) => {
    var content = a;
    var tempallword = $('<div>').html(content).text();
    let regexp: RegExp = /[ ]*[\.]*[<A-z>'"|\d]+[\.]*[ ]*/g;
    const str = tempallword;
    //const array=regexp.exec(str);
    const array = [...str.matchAll(regexp)];

    return array.length
  }
  const handleCalibration = (e: any, total: any) => {
    let calibrationArray = [...state.calibrationArray]

    let calibration_object = { total: total, text: e.target.value }
    let findIndex = calibrationArray.findIndex((cal: any) => cal.total == total);

    if (findIndex > -1) {
      calibrationArray[findIndex] = calibration_object
    } else {
      calibrationArray.push(calibration_object)
    }

    setState((prev: any) => ({ ...prev, calibrationArray: [...calibrationArray] }))

  }

  // useEffect(()=>{
  //         if(state.stdanswer.length>0 && state.mode == "examMode"){
  //           onSaveQuestion(state)
  //           requestWithAxios(
  //             BASE_URL + "student/saveq.php",
  //             state
  //           );
  //         }
  // },[state.stdanswer])

  let colors = [
    "blue",
    "green",
    "yellow"
  ]

  return (
    // <div className="mx-5">
    <div className="">


      <div className="row rounded my-3 p-0 ll shadow bg-question">
        {state.mode == "examMode" && (
          <div className="row col-12">
            <div className="  pt-4 pl-4 col-4" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}> Q {props.index}
            </div>
            <div className="  p-3 col-6 d-flex justify-content-end" style={{ color: props.color || "#fff", fontSize: "28px", paddingLeft: "10px", fontWeight: "bold" }}>
              <input type="hidden" ref={count} value={spenttime} />
              <div>
                Spent time : <label id="minutes">{MapSecondsToTime(Math.floor(spenttime / 60))}</label>:<label id="seconds">{spenttime > 0 ? MapSecondsToTime(+spenttime % 60) : "00"}</label>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 row mx-auto align-items-center bg-question rounded p-3 text-white">
          <div className="col-12 row justify-content-between">

            <div className="d-flex flex-column">
              <div className="form-group d-flex align-items-center my-3">
                <span className="col-auto text-shadow">
                  {state.etype == "capstone" ? "Level" : "Total mark :"}
                </span>
                {state.mode === "editingMode" ? (
                  <input
                    type="text"
                    className="col form-control"
                    name=""
                    disabled={props.etype == "capstone" ? true : false}
                    value={state.total_mark}
                    onChange={(e) => {
                      if ((typeof +e.target.value == "number" && +e.target.value > -1) || e.target.value == "") {
                        let total_mark = +e.target.value;
                        let temp_calibration: any = [];
                        for (let index = total_mark; index > 0; index--) {
                          temp_calibration.push({ total: index, text: "" });

                        }

                        setState((prev: any) => ({ ...prev, calibrationArray: [...temp_calibration], total_mark: +total_mark }))

                      }
                    }
                    }
                    required
                  />
                ) : state.mode === "examMode" ||
                  state.mode === "gradingModeTeacher" ||
                  state.mode === "gradingModeStudent" ? (
                  state.etype == "capstone" ? "" : <span className="text-shadow">{state.total_mark}</span>

                ) : null}
                {state.mode === "gradingModeTeacher" ? (
                  state.etype == "capstone" ? <>
                    <span className="col-auto border-left ml-2"> </span>
                    <select
                      className="col form-control"
                      name=""
                      id={`Q-${props.index}-Score`}
                      style={{ background: GradingColors[parseInt(state.score) - 1] }}
                      onChange={(e) => {
                        let score = e.target.value
                        let updatedState = { ...state, score }
                        onSaveQuestion(updatedState)
                        setState(updatedState)
                        $(`#Q-${props.index}-Score`).css('background', GradingColors[parseInt(score) - 1]);
                      }
                      }
                      required
                    >
                      <option value={1} selected={state.score == "1"} style={{ background: GradingColors[0] }}>Red</option>
                      <option value={2} selected={state.score == "2"} style={{ background: GradingColors[1] }}>Yellow</option>
                      <option value={3} selected={state.score == "3"} style={{ background: GradingColors[2] }}>Green</option>
                      <option value={4} selected={state.score == "4"} style={{ background: GradingColors[3] }}>Blue</option>

                    </select>
                  </> :
                    <>
                      <span className="col-auto border-left ml-2">Score :  </span>
                      <input
                        type="number"
                        min={0}
                        className="col form-control"
                        name=""
                        id=""
                        value={state.score}
                        onChange={(e) => {

                          let score = e.target.value
                          let updatedState = { ...state, score }
                          onSaveQuestion(updatedState)
                          setState(updatedState)
                        }
                        }
                        required
                      />
                    </>
                ) : state.mode === "gradingModeStudent" ? (
                  <>
                    <span className="border-left ml-2 pl-2 text-shadow">
                      {state.etype == "capstone" ? <span className="m-1 p-1 d-block" style={{ width: "65px", height: "30px", backgroundColor: GradingColors[parseInt(state.score) - 1] }} ></span> : `Score :  ${state.score}`}
                    </span>
                  </>
                ) : null}
              </div>
            </div>
          </div>



          {
            ("editingMode" != state?.mode) ? null :
              <div className="col-12 mb-5 row justify-content-center align-items-center">
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                >
                  Short Answer/Essay
                </div>
              </div>

          }







          <div className="col-12 row justify-content-center align-items-center">
            {state.mode === "editingMode" ? (
              <div className="col-12 w-100 row">
                <RichBox
                  onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v })); handleCount(v); }}
                  onChangeTxt={(a: any) => { setState((prev: any) => ({ ...prev, headertxt: a })); handleCount(a); }}
                  defaultValue={state.header}
                  height={"100%"}
                ></RichBox>
                <div className="mt-5 col-12 row d-flex justify-content-center">
                  <div className="col-6 ">

                    <span className="mt-1">word limit :  </span>
                    <input
                      type="number"
                      min={0}
                      className="col-12 form-control"
                      name=""
                      id=""
                      value={state.wordlimit}
                      onChange={(e) => {
                        let wordLimit = e.target.value
                        setState((prev: any) => ({ ...prev, wordlimit: wordLimit }))
                      }
                      }
                      required
                    />
                  </div>
                  <div className="col-6 ">
                    <span className="mt-1"> Placeholder text for students :  </span>
                    <textarea
                      className="col-12 form-control"
                      rows={3}
                      value={state.answer_place_holder}
                      onChange={(e) => {
                        let answer_place_holder = e.target.value
                        setState((prev: any) => ({ ...prev, answer_place_holder: answer_place_holder }))
                      }
                      }
                      required
                    ></textarea>
                  </div>
                </div>

                <hr className="col-10" style={{ color: "white", backgroundColor: "white" }} />
                <div className="row col-12 ">

                  <div className="col-4 row my-3 ">
                    <input
                      type="checkbox"
                      name="show_word_count"
                      className="mr-2"
                      // checked={state.show_word_count}
                      checked={(state.show_word_count == "true") || (state.show_word_count == true)}
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                      id="show_word_count"
                      onChange={(e) => {
                        let show_word_count = e.target.checked
                        setState((prev: any) => ({ ...prev, show_word_count: show_word_count }))

                      }

                      }
                    />

                    <label htmlFor="show_word_count" style={{ cursor: "pointer" }}>
                      Show word count
                    </label>
                  </div>
                  <div className="col-4 row my-3 ">
                    <input
                      type="checkbox"
                      name="show_word_limit"
                      className="mr-2"
                      // checked={state.show_word_limit}
                      checked={(state.show_word_limit == "true") || (state.show_word_limit == true)}
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                      id="show_word_limit"
                      onChange={(e) => {
                        let show_word_limit = e.target.checked
                        setState((prev: any) => ({ ...prev, show_word_limit: show_word_limit }))

                      }
                      }
                    />
                    <label htmlFor="show_word_limit" style={{ cursor: "pointer" }}>
                      Show word limit
                    </label>
                  </div>
                  <div className="col-4 row my-3 ">
                    <input
                      type="checkbox"
                      name="allow_over_count"
                      checked={(state.allow_over_limit == "true") || (state.allow_over_limit == true)}
                      className="mr-2"
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                      id="allow_over_count"
                      onChange={(e) => {
                        let allow_over_limit = e.target.checked
                        setState((prev: any) => ({ ...prev, allow_over_limit: allow_over_limit }))

                      }
                      }
                    />
                    <label htmlFor="allow_over_count" style={{ cursor: "pointer" }}>
                      allow submissions over word limit
                    </label>
                  </div>
                </div>
              </div>
            ) : state.mode === "examMode" ||
              state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
            <>
             {props.passage ? (
                  <div
                    className="bg-white w-100 text-dark rounded align-items-center p-3 mb-3"
                    dangerouslySetInnerHTML={{ __html: `<h4>Passage : </h4> ${props.passage.header}` }}
                  ></div>
                ) : ""}
                <div
                  className="bg-white w-100 text-dark rounded align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: `<h5>Question : </h5> ${state.header}` }}
                ></div>
            </>
            ) : (
              <div></div>
            )}
          </div>

          {state.mode === "editingMode" ? (
            <DifficultLevel
              difficult={state.difficult}
              cognitive={state.cognitive}
              onChangeDifficult={(difficult: any) => { setState((prev: any) => ({ ...prev, difficult: difficult })) }}
              onChangeCognitive={(cognitive: any) => { setState((prev: any) => ({ ...prev, cognitive: cognitive })) }}
            />

          ) : null}
          <div className="col-12 mt-5" style={{ marginTop: "100px" }}></div>
          {state.mode === "editingMode" ? (
            <LearningStandards
              all_targets={state.all_targets?.length > 0 ? state.all_targets : props.course_standards_targets}
              question={state}
              coursestandards={props.coursestandards}
              onChangeStanderd={(standardid: any) => {
                setState((prev: any) => ({ ...prev, standardid: standardid }));
              }}
              updateTargets={(all_targets: any) => {
                setState((prev: any) => ({ ...prev, all_targets: all_targets }));
              }}
              onChangeTargets={(learning_targets: any) => {
                setState((prev: any) => ({ ...prev, learning_targets: learning_targets }));
              }}
            ></LearningStandards>

          ) : null}
          <div className="col-12 row justify-content-center align-items-center">
            {state.mode === "examMode" ? (
              <>
                <div className="col-12 w-100 row mt-3 justify-content-center align-items-center">
                  <RichBox
                    onChange={(a: any) => {


                      onSaveQuestion({ ...state, stdanswer: a });



                    }}
                    onChangeTxt={(a: any) => {

                      onSaveQuestion({ ...state, stdanswer: a })
                      setState((prev: any) => ({ ...prev, stdanswer: a }));
                    }}
                    defaultValue={state?.stdanswer}
                    height={"100%"}
                    //maxCharCount={1000}
                    charCounter={true}
                    placeholder={state?.answer_place_holder}
                  ></RichBox>

                  <p className="mt-1 bg-danger text-white" style={{ fontSize: "1.5rem" }}>{(state.overcount != 'false' && state.overcount != false && state.overcount != 0 && state.overcount == true ? "Warning! you have exceeded word count!" : "")}</p>
                </div>
                <div className="mt-2 col-12 row justify-content-center">

                  {((state.show_word_count === true) || (state.show_word_count === "true")) ? (<p className="p-3 m-1 bg-info text-white">word count: {state.count}</p>) : null}
                  {((state.show_word_limit === true) || (state.show_word_limit === "true")) ? (<p className="p-3 m-1 bg-info text-white">Max word count: {state.wordlimit}</p>) : null}
                  <p className="p-3 m-1 bg-info text-white">Allowed to submit exceeded word count: {(state.allow_over_limit == "true" ? "yes" : "no")}</p>
                </div>
              </>
            ) :
              state.mode === "gradingModeTeacher" ||
                state.mode === "gradingModeStudent" ? (
                <div
                  className="bg-white w-100 mt-4 text-dark rounded align-items-center p-3"
                  dangerouslySetInnerHTML={{ __html: state.stdanswer }}
                ></div>
              ) : (
                <div></div>
              )}
            {state.mode === "gradingModeTeacher" ||
              state.mode === "gradingModeStudent" ? (
              <div className="col-12 mt-5 row align-items-start align-content-start mx-auto">
                <label className="align-self-start">Feedback</label>
                <textarea
                  className="form-control align-self-stretch "
                  name=""
                  id=""
                  value={state.feedback == "" || state.feedback == "null" ? "" : state.feedback}
                  rows={5}
                  disabled={
                    state.mode === "gradingModeStudent" ? true : false
                  }
                  onChange={(e) => {
                    let feedback = e.target.value;
                    let updatedState = { ...state, feedback }
                    onSaveQuestion(updatedState)
                    setState((prev: any) => ({ ...prev, feedback: feedback }))
                  }
                  }
                ></textarea>
              </div>
            ) : null}
          </div>

          {
            // state.mode === "editingMode" && (
            //   <div style={{ display: "inline", width: "50%", marginTop:"30px" }}>
            //   <label style={{ marginRight: "10px", marginTop: "10px" }}> Analytical Rubrics</label>
            //   <label className="switch">
            //     <input type="checkbox" name="attendees"
            //       checked = {state.rubricsmode == "Holistic" ? true :false}

            //     onChange={() => {
            //       setState((prev: any) => ({ ...prev, rubricsmode: state.rubricsmode == "Analytical" ? "Holistic" : "Analytical" }))
            //     }} />
            //     <span className="slider round"></span>
            //   </label>
            //   <label style={{ marginLeft: "10px", marginTop: "15px" }}> Holistic Rubrics</label>
            // </div>
            // )

          }

          {state.mode === "editingMode" ? (
            <div className="row col-12">

              <div className=" col-md-6 my-2"> Analytical Rubrics :
                <div className="  row  col-12 ">
                  {
                    state.calibrationArray?.sort((a: any, b: any) => b.total - a.total).map((cal: any, index: any) => {
                      let total = cal.total
                      if (total == 1 && props.etype == "capstone") {
                        return (
                          <div></div>)
                      }
                      return (
                        <div className="col-6 my-3" key={total} >
                          <label>The score will be {total}
                            {
                              props.etype == "capstone" ? (<span >  ({colors[index]})</span>) : ``
                            } if :
                          </label>
                          <textarea className="form-control" onChange={(e: any) => { handleCalibration(e, total) }}
                            value={state.calibrationArray?.find((cal: any) => cal.total == total)?.text ?? ""}
                          ></textarea>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className=" col-md-6 my-2"> Holistic Rubrics :
                <div className="  row  col-12 ">
                  {
                    <div className="col-12 my-3"  >
                      <label>
                      </label>
                      <textarea className="form-control" onChange={(e: any) => {
                        let value = e.target.value
                        let temp_calibration = state.calibration;
                        console.log(temp_calibration)
                        temp_calibration = {
                          Analytical: ""

                        }
                        temp_calibration["Holistic"] = value;
                        console.log(temp_calibration)
                        setState((prev: any) => ({ ...prev, calibration: temp_calibration }))
                      }}
                        value={state.calibration?.Holistic}
                      ></textarea>
                    </div>
                  }
                </div>
              </div>
            </div>
          ) : ""}

          {(state.mode == "editingMode" || state.mode == "answeringMode") && <div className="text-center col-12">
            <button
              className="btn btn-warning btn-lg rounded col-3"
              onClick={() => onSaveQuestion(state)}

            >
              {" "}
              Save
            </button>
          </div>}
        </div>
      </div>
    </div>
  );


}

export default Essay