import React, { Component } from "react";

import RichBox from "./RichBoxComponent";


export interface OptionProps {
  id: string;
  option: string;
  authorNote: string;
  index: number;
  mode: string;
  isTrue: boolean;
  selected?: boolean;
  partialMode: boolean;
  onOptionUpdate?: any;
  onAddScoreHandler?: any;
  onDeleteOption?: any;
  onClickHandler?: any;
  onChooseCorrect?: any;
  optiontxt:any;
  required:boolean;
  one_line_options :boolean;
  isModelAnswerMode :boolean
}

// const selectedStyle = {
//   cursor: "pointer",
//   backgroundColor: "#9df3b1 !important",
//   border: "4px solid #098434 !important",
//   borderRadius: "10px !important",
// };

class Option extends Component<OptionProps, any> {
  /*   state = {
    id: this.props.id,
    option: this.props.option ?? "",
    authorNote: this.props.authorNote ?? "",
  }; */
  // static getDrivedStateFromProps() {

  // }
  
  onChangeHandler = (e: any) => {
    // this.setState({ authorNote: e.target.value });
    this.props.onOptionUpdate({ ...this.props, authorNote: e.target.value });
  };
  onChangeHandler2 = (e: string) => {
    //this.setState({ option: e });
    this.props.onOptionUpdate({ ...this.props, option: e });
  };

  onClickHandler = () => {
    this.props.onClickHandler(this.props.id);
  };

  onChooseCorrect = () => {
    this.props.onChooseCorrect(this.props.id);
  };
  render() {
  
    return (
      
      <div  className={` ${this.props.mode === "examMode" && this.props.one_line_options == true ? "col-sm-3" : "col-12"} m-2 row align-items-baseline`}>
       
        {this.props.mode === "editingMode"  ? (
          <React.Fragment>
            <span className="col-auto mr-1"> {this.props.index} -</span>
            <input
                type="checkbox"
                className="mr-1"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                onChange={this.onChooseCorrect}
                checked={this.props.isTrue}
              />
            <div className={ `col p-0 rounded ${
                this.props.isTrue ? "selected" : "not-selected"
              }`} style={{ overflow: "hidden"  }}>
                {
                 !this.props.isModelAnswerMode?(
                  <RichBox
                  onChange={this.onChangeHandler2}
                
                  defaultValue={this.props.option}
                  height={"10px"}
                ></RichBox>
                 ): (
                  <div
            className={` w-100 text-dark  row align-items-center p-3 ${
              this.props.selected == true && this.props.isTrue == true ? "gradingMode" : this.props.selected && !this.props.isTrue ? "isWrong" : " normal"
            }`}
            dangerouslySetInnerHTML={{ __html: this.props.option }}
          ></div>
                 )
                }
             
            </div>

            { !this.props.isModelAnswerMode ? ( <span
              className="col-auto ml-1"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.onDeleteOption(this.props.id)}
            >
              <i className="fas fa-trash-alt"></i>
            </span>) :""}
           
          </React.Fragment>
        ) : this.props.mode === "examMode" ? (
          <div
            className={` w-100 text-dark  col-12 align-items-center p-3 ${
              this.props.selected ? "choose-it" : "not-selected"
            }`}
            onClick={() => {
              this.onClickHandler();
              this.props.onAddScoreHandler();
            }}
            dangerouslySetInnerHTML={{ __html: this.props.option }}
          ></div>
        ) : this.props.mode === "answeringMode"  ? (
          <div className="col-12 row justify-content-center align-items-center">
            <div className="col-auto mr-2">
              <input
                type="checkbox"
                style={{ width: "20px", height: "20px", cursor: "pointer" }}
                onChange={this.onChooseCorrect}
                checked={this.props.isTrue}
              />
            </div>
            <div
              className={`col-sm text-dark  row align-items-center p-3 ${
                this.props.isTrue ? "selected" : "not-selected"
              }`}
              onClick={this.onChooseCorrect}
              dangerouslySetInnerHTML={{ __html: this.props.option }}
            ></div>
          </div>
        ) : this.props.mode === "gradingModeTeacher" ||
          this.props.mode === "gradingModeStudent" ? (
          <div
            className={` w-100 text-dark  row align-items-center p-3 ${
              this.props.selected == true && this.props.isTrue == true ? "gradingMode" : this.props.selected && !this.props.isTrue ? "isWrong" : " normal"
            }`}
            dangerouslySetInnerHTML={{ __html: this.props.option }}
          ></div>
        ) : (
          <div></div>
        )}
        {this.props.mode === "editingMode" ? (
        
            
            
            
            <div className="col-12  justify-content-center d-flex p-3 fs-1">
            <label className="col-auto text-white">Author note : </label>
           
              <input
               
                className="col-8 ml-3 form-control my-1"
                value={this.props.authorNote}
                onChange={this.onChangeHandler}
              ></input>
            </div>
       
        ) : this.props.mode === "gradingModeTeacher" ||
          this.props.mode === "gradingModeStudent" ? (
          <div className="col-12 row  align-items-center">
            <div
              className="pl-5 row align-items-baseline "
              style={{ width: "90%" }}
            >
              {this.props.authorNote != "" && `Author note : ${this.props.authorNote }`}  
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Option;
