import React, { useEffect, useRef, useState } from "react";
import "../../../css/DashBoard.css";
import RichBox from "./RichBoxComponent";
import { makeId } from "../../../helpers/func";




// editingMode
// examMode
// gradingModeTeacher
// gradingModeStudent

const FileUploadEnhanced = (props: any) => {

  const [state, setState] = useState<any>({
     id: makeId(10),
    header :"",
    selectedQuestions :[]
  });


  useEffect(()=>{
      if(props.passage !=null) {

        setState({...props.passage})}
        
      },[props.passage])

 


      
      
      
      console.log(state)
 

 

  
  return (
    // <div className="mx-5">
    <div className="">


      <div className="row rounded my-3 p-0 ll shadow bg-question">
      
        <div className="col-12 mx-auto bg-question row align-items-center rounded p-3 text-white">
         



           <h4 className="mx-1">Passage :</h4>




          <div className="col-12 mb-5 row justify-content-center align-items-center">
            {props.mode === "editingMode" ? (
              <div className="col-12 w-100 row justify-content-center align-items-center">
                <RichBox
                  onChange={(v: any) => { setState((prev: any) => ({ ...prev, header: v }));  }}
                  onChangeTxt={(a: any) => { setState((prev: any) => ({ ...prev, headertxt: a }));  }}
                  defaultValue={state.header}
                  height={"10px"}
                ></RichBox>
              </div>
            ) : props.mode === "examMode" ||
              props.mode === "answeringMode" ||
              props.mode === "gradingModeTeacher" ||
              props.mode === "gradingModeStudent" ? (
              <div
                className="bg-white w-100 text-dark rounded align-items-center p-3"
                dangerouslySetInnerHTML={{ __html: state.header }}
              ></div>
            ) : (
              <div></div>
            )}
          </div>

          
      



  



          {(props.mode == "editingMode" || props.mode == "answeringMode") && <div className="text-center col-12">
            <button
              className="btn btn-warning btn-lg rounded col-3 mt-3"
              onClick={() => props.onSavePassage(state)}

            >
              {" "}
              Save
            </button>
          </div>}
        </div>
      </div>

    


    </div>
  );
  // }

}
export default FileUploadEnhanced