import React, { useEffect, useState } from 'react';
import NormalDistributionChart from './NormalDistributionChart';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL } from '../../variables/api';
import Cookies from 'js-cookie';
import jwt_decode from "jwt-decode";

const NormalDistribution: React.FC = () => {
  const token = Cookies.get("quiztoken");
  const user:any  = jwt_decode(token || "");
  const userRole_rolename = user?.data?.userRole?.rolename;
  const [studentsExams, setStudentsExams] = useState<Array<number>|null>(null)
  const {id,version}:any = useParams();
  const exam_id=id;
  const [outof, setOutof] = useState(100)
  const [original_mean, setOriginal_mean] = useState(0)
  const [original_std_dev, setOriginal_std_dev] = useState(0)

  const save_new_score = (shift_score: number, mean: number, std_dev: number) => {
    Swal.showLoading()
    axios.post(`${BASE_URL}admin/updatestudentsexams.php`, { exam_id, shift_score, mean, std_dev }).then((res: any) => {
      
      Swal.close();
    }).catch(() => {
      Swal.close();
    })



  }


  useEffect(() => {
    //dispatch(AssessmentActionCreator.GetAssessment(exam_id))
    Swal.showLoading()
    axios.post(`${BASE_URL}admin/getstudentsexams.php`, { exam_id: exam_id  ,version}).then((res: any) => {
      let exams = res.data.data.exams.filter((x:any)=>x.status === "Graded")
      if (exams?.length){
        setOutof((parseFloat(exams.outof) - 0))
      }
      if (res?.data?.data?.exam) {
        setOriginal_mean((res?.data?.data?.exam as any)?.mean)
        setOriginal_std_dev((res?.data?.data?.exam as any)?.std_dev)
      }
      setStudentsExams(exams?.map((x: any) => x.total_score - 0))
      Swal.close();
    }).catch(() => {
      Swal.close();
    })
  }, [])
  return (
    userRole_rolename !== "Admin" ? 
    <h1>You don't have access to this page</h1>
    :studentsExams=== null?<div>Loading...</div>:
      <div>
        <h1>Student Scores Chart</h1>
        <NormalDistributionChart
          scores={studentsExams}
          save_new_score={save_new_score}
          outof={outof}
          props_mean={original_mean}
          props_std_dev={original_std_dev}
        />
      </div>
  );
};


export default NormalDistribution;