import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, withRouter } from 'react-router';
import { makeId } from '../../helpers/func';
import { BASE_URL } from '../../variables/api';
import axios from 'axios';
import ImageHighlightComponent from '../questions/ImageHighlightComponent';
import FillTheBlank from '../questions/FillTheBlank';
import FileUploadEnhanced from '../questions/FileUploadEnhanced';
import AudioComponent from '../questions/AudioComponent';
import VideoComponent from '../questions/VideoComponent';
import EssayComponent from '../questions/EssayComponent';
import TrueOrFalse from '../questions/TrueOrFalse';
import LabelQComponent from '../questions/LabelQComponent';
import OrderComponent from '../questions/OrderComponent';
import MCQComponent from '../questions/MCQComponent';
import Swal from 'sweetalert2';
import $ from "jquery";
import "../../css/DashBoard.css";
import "../../css/assessment.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { NavLink } from 'react-router-dom';
import ProcessCardSingleState from '../questions/ProcessCardSingleState';
import MatchQComponent from '../questions/MatchQComponent';
import { MathJax } from 'better-react-mathjax';
import RichBox from '../questions/smallComponents/RichBoxComponent';
import Passage from '../questions/smallComponents/Passage';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


function format(date: any, f: any) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}


const UpdateModelAnswers = () => {
    const location = useLocation();
    const history = useHistory();

 

    const loading = () => {
        Swal.fire({
            html: '<div style="color:blue;"> Loading....... </div>',
            showConfirmButton: false,
        });
    };
   
   

    const GetAssessment = (id: any, version: any) => {

        axios.post(`${BASE_URL}admin/update_model_answers/get_exam_questions.php`, {
            id: id, version
        }).then((res: any) => {

            if (isAi == "true") {
                let questions: any = [];
                let exam_from_local_storage = localStorage.getItem("exam_questions");
                if (exam_from_local_storage) {
                    let exam = JSON.parse(exam_from_local_storage);
                    if (exam.id == id)
                        questions = exam?.questions
                }
                setState((prev: any) => ({ ...prev, ...res.data.data, questions: questions }));
            } else {
                let exam = { ...res.data.data };
                let temp_all_selected_passages_questions: any = [];
                exam.passages?.forEach((passage: any) => {
                    passage.selectedQuestions = exam.questions.filter((q: any) => q.passage_id == passage.id)?.map((q: any) => {
                        return {
                            label: `${htmlToText(q.header).substring(0, 200)}`,
                            value: q.id
                        }
                    })

                    temp_all_selected_passages_questions.push(...passage.selectedQuestions)
                });

                setState((prev: any) => ({ ...prev, ...exam, all_selected_passages_questions: temp_all_selected_passages_questions }));
            }
             Swal.close();
        }).catch((err: any) => {
            Swal.fire("Something went wrong , please check the internent and refresh the page ", "", "error");

        })
    };
   



   


    const searchParams: any = new URLSearchParams(location.search);
    const examId = searchParams.get("id");
    const version = searchParams.get("v");
    const isAi = searchParams.get("isAi");
    const groupId = searchParams.get("course_id");
    const categoryId = searchParams.get("category_id");
  
    const periodId = searchParams.get("period_id");
    const assesmentTitle = searchParams.get("assessment_title")
    const assessment_prefix = assesmentTitle?.split("-")[0];
    const assessment_type = assesmentTitle?.split("-")[2];
    const sequance = assesmentTitle?.split("-")[3];
    const number_of_trials = assesmentTitle?.split("-")[4];


    // mapStateToProps

    let date = new Date();
    const [state, setState] = useState<any>({
        id: makeId(10),
        name: assesmentTitle,
        grade: "",
        type: "",
        etype: "regular",
        course: "",
        gradingcategoryid: categoryId,
        gradingperiodid: periodId,
        sequance: "",
        number_of_trials: "",
        assessment_type: "",
        assessment_prefix: "",
        passages: [],
        startdate:
            format(new Date(), "yyyy-MM-dd") +
            "T" +
            new Date().toLocaleString()?.split(", ")[1],
        enddate:
            format(date.setDate(new Date().getDate() + 1), "yyyy-MM-dd") +
            "T" +
            new Date().toLocaleString()?.split(", ")[1],
        nooftrials: 1,
        courses: [],
        teachers: [],
        coursegroup_id: groupId,
        teacherids: [],
        grading_teachers_ids: {},
        editing_teachers_ids: {},
        submitalert: false,
        periods: [],
        questions: [],
        open: false,
        selectedgroups: [],
        selectedteachers: [],
        weeknumber: 1,
        istimed: 0,
        issecured: 0,
        isprivate: 0,
        ismandatory: 0,
        duration: 0,
        categories: [],
        all_selected_passages_questions: [],
    });


    // karim stats 
    const [modalContent, setModalContent] = useState<any>(null);
    

  

    useEffect(() => {


        let assessment: any = null;

        if (examId && examId != "null") {
            GetAssessment(examId, version)
          
            $("#preloader").hide();
            assessment = assessment;
            if (assessment == null) {
                return;
            }
        } else {

            $("#preloader").hide();
        }
        

    }, [])


    const onCloseModal = (Q: any = null) => {
        // save q in array in the store     
        setState((x: any) => ({ ...x, open: false }))
        // setState({ open: false });
    };
    const onOpenPassageModal = (passage: any = null) => {

        setModalContent(<Passage passage={passage} mode="editingMode" onCloseModal={() => { onCloseModal() }} onSavePassage={(passage: any) => { onSavePassage(passage) }} />)
        setState((x: any) => ({ ...x, open: true }))

    };

    const onSavePassage = (passage: any) => {
        let tempPassages = [...state.passages]
        let foundPassageIndex = tempPassages.findIndex((p) => p.id == passage.id)
        if (foundPassageIndex != -1) {
            tempPassages[foundPassageIndex] = passage
        } else {
            tempPassages.push(passage)
        }

        setState((x: any) => ({ ...x, open: false, passages: tempPassages }))
    }

    const onSaveQuestion = (question: any) => {
        
        let tempQuestins = [...state.questions]
        let q = tempQuestins.find((q: any) => q.id == question.id);
        if (q) {
            let questions = [...state.questions];
            let index = questions.indexOf(q);
            questions[index] = question;
            setState((prev: any) => ({ ...prev, questions: questions }))

        } else {
            setState((prev: any) => ({ ...prev, questions: tempQuestins.concat(question) }))

        }
        Swal.showLoading();
        axios.post(`${BASE_URL}admin/update_model_answers/update_question_model_answer.php` , {question}).then((res:any)=>{
           Swal.fire(`Model answer was updated successfully` , "" , "success")
        }).catch(()=>{
            Swal.fire("Something went wrong" ,"" , "error")
        })
        onCloseModal();
    };

   

  



    const editQuestionsRenderer = (s: any, q: any) => {
        switch (s) {
            case "MCQ":
                setModalContent(
                    <MCQComponent
                      
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                        isModelAnswerMode = {true}
                    />
                );
                return null;
            case "OrderComponent":
                q.mode = "answeringMode"
                setModalContent(
                    <OrderComponent
                  
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                        isModelAnswerMode = {true}

                    />
                );
                return null;
            case "MatchQComponent":
                q.mode = "answeringMode"

                setModalContent(
                    <MatchQComponent
                  
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                        isModelAnswerMode = {true}

                    />
                );
                return null;
           
            case "TrueOrFalse":

                setModalContent(
                    <TrueOrFalse
                     
                        quest={q}
                        onCloseModal={() => onCloseModal()}
                        onSaveQuestion={(q: any) => onSaveQuestion(q)}
                        isModelAnswerMode = {true}

                    />
                );
                return null;
           
           
           
            default:
                return (
                    <h1>
                        Select Question Type From The Left Menu To Add It To The
                        Assessment
                    </h1>
                );
        }
    };



    const htmlToText = (html: any) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        return doc.body.textContent || "";
    };
    return (
        <MathJax>
            <div>
                <div>
                    <div className=" d-flex d-md-block flex-nowrap wrapper ">
                        <div
                            className="col-md-2 float-left col-1 pl-0 pr-0 collapse width show"
                            id="sidebar"
                        >

                        </div>
                        <div className="col-md-12 float-left mx-auto pl-md-2 pt-2 main">
                            <div>
                                <div className="col-12 mx-auto align-items-center bg-question rounded p-3 text-white">
                                    <NavLink to={"/dashboard/assessments"} className="btn btn-secondary mb-3">
                                        <i className="fa fa-arrow-left" aria-hidden="true" />
                                    </NavLink>


                    <div className='d-flex justify-content-center'>

                    <div className={`text-center  mt-5 `} style={{ fontWeight: "bold", padding :"10px", width :"50%", fontSize: "25px", border: "solid white 1px" }}>Exam Title : {state?.name}</div>
                    </div>
                                        
                    <h4 className='m-4 ' > <span style={{color:"red"}}>*</span>Please note that the questions displayed here will only include MCQs, True or False, Match, and Order types.</h4>
                                    
                                    <h3 className="text-shadow mt-2 ml-3">Questions : {state.questions?.length}</h3>

                                    <div className="col-12" >

                                        {state.questions?.map((q: any, index: number) => {
                                            return (
                                                <div
                                                            className="row  mx-auto align-items-center"
                                                            key={index}
                                                           
                                                        >
                                                            <div className="col-lg-9 mt-2 ">
                                                                <div
                                                                    className="bg-white text-dark rounded align-items-center mt-1 pl-5 "
                                                                    dangerouslySetInnerHTML={{ __html: `<p>Q${index + 1}-&nbsp;</p>` + q.header }}                        ></div>
                                                            </div>
                                                            <div className="col-md-3 col-lg-1 mt-2">
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        editQuestionsRenderer(q.type, q);
                                                                        // setState({ open: true });
                                                                        setState((x: any) => ({ ...x, open: true }))
                                                                    }}
                                                                    className="btn btn-block text-white btn-warning mt-1"
                                                                >
                                                                    Edit
                                                                </button>
                                                            </div>

                                                        </div>

                                            );
                                        })}

                                    </div>

                                   
                                </div>
                                <Modal
                                    classNames={{
                                        modal: "customModal",
                                    }}
                                    closeOnOverlayClick={false}
                                    open={state.open}
                                    onClose={() => onCloseModal()}
                                    center
                                >
                                    {modalContent}
                                    {/*QuestionsRenderer()*/}
                                </Modal>



                            </div>
                        </div>
                    </div>
                </div>
            </div></MathJax>
    )
}
export default withRouter(UpdateModelAnswers);
// export default UpdateModelAnswersFuncComp