import React, { useEffect, useRef, useState } from "react";
import { Component } from "react";
import { NavLink, useParams, withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { actionCreators as AssessmentActionCreator } from "../../../store/Assessment";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import PublishAssessmentModal from "./PublishStudentsModal";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../variables/api";
import { ivy_assessment_platform_datatable_tabs_localStorageItem_name, update_ivy_assessment_platform_datatable_tabs_arr } from "../../../helpers/func";
import Modal from "react-responsive-modal";
import Select from "react-select";

import "./Assessments.css"
require("datatables.net");
require("../../MyComponents/dataTables.bootstrap4.min");

const Assessments = () => {
  const params = useParams()
  const navigate = useHistory();
  const [modal, setModal] = useState(false)
  const [group_id, setGroup_id] = useState(null)
  const [selectedAssessment_id, setSelectedAssessment_id] = useState(null)
  const { gotdata } = useSelector((state: any) => state.Assessment);
  const { assessment } = useSelector((state: any) => state.Assessment);
  const [assessments, setAssessments] = useState([])
  const [isRetake, setIsRetake] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [anotherVersionModal, setAnotherVersionModal] = useState(false)
  const assessmentType: any = useRef(null);
  const selectedVersion: any = useRef(null);
  const [examVersionsModal, setExamVersionsModal] = useState(false)
  const [action, setAction] = useState("");
  const [examVersions, setExamVersions] = useState([])
  const [allExamVersions, setAllExamVersions] = useState([])
  const [groups, setgroups] = useState([]);
  const [courses, setCourses] = useState([]);
  const [years, setYears] = useState([]);
  const [filterData, setFilterData] = useState({
    year_id: 0,
    group_id: 0,
    coursegroup_id: 0,
    fromDate: "",
    toDate: "",
    grading_period_id:0,

  })
  const [grades, setGrades] = useState([]);
  const [grading_periods, setGradingPeriods] = useState([]);
  const [examsCount, setExamsCount] = useState(0);


  const [pagination, setPagination] = useState({
    pageNumber: 1,
    rowsPerPage: 10,

  })
  const dispatch = useDispatch();
  const getExams = (data: any) => {
    Swal.showLoading();
    axios
      .post(
        BASE_URL + "admin/getexams.php", data
      )
      .then((res) => {
        setAssessments(res.data.data.exams)
        setExamsCount(res.data.data.examsCount)
        setYears(res.data.data.years)
        setGrades(res.data.data.grades)
        setGradingPeriods(res.data.data.grading_periods)
        setFilterData((prev: any) => ({ ...prev, year_id: res.data.data.year_id }))

        setIsLoaded(true)
        Swal.close();
      })
  }



  const [role, setRole] = useState("")


  // componentDidMount
  useEffect(() => {

    $("#preloader").hide();

    getExams({ id: (params as any).id, ...pagination });

    const token = Cookies.get("quiztoken");
    let decodedToken: any = {};

    decodedToken = jwt_decode(token || "");

    setRole(decodedToken.data?.userRole?.rolename)

  }, [])



  useEffect(() => {
    if (gotdata) {
      navigate.push({
        pathname: "/assessmentcomponent",
        search: '?id=' + assessment.id
      });
    }
  }, [gotdata])




  const editAssessmentHandelr = (id: any, version: any, isAi = false) => {
    navigate.push({
      pathname: "/assessmentcomponent",
      search: `?id=${id}&v=${version}&isAi=${isAi}`
    });
  };
  const getExamVersions = (id: any, action: any) => {
    setSelectedAssessment_id(id)
    setAction(action)
    if (allExamVersions?.length == 0) {
      // get all exam versions at first time 
      axios.get(BASE_URL + "admin/get_exam_versions.php?exam_id=" + id).then((res) => {
        console.log(res)
        let foundExamVersion = res.data.data?.find((exam_version: any) => exam_version.exam_id == id);

        if (!foundExamVersion || foundExamVersion == undefined) {
          foundExamVersion = { exam_id: id, versions: "1" }
        }

        setExamVersions(foundExamVersion.versions.split(","))
        let tempAllExamVersions: any = [...allExamVersions]
        tempAllExamVersions.push(foundExamVersion)
        setAllExamVersions(tempAllExamVersions)
      }).catch((err) => {
        console.log(err)
      })
    } else {
      let foundExamVersion: any = allExamVersions?.find((exam_version: any) => exam_version.exam_id == id);

      if (foundExamVersion)
        setExamVersions(foundExamVersion.versions.split(","))
      else {
        // if there is no version check again
        axios.get(BASE_URL + "admin/get_exam_versions.php?exam_id=" + id).then((res) => {
          let foundExamVersion = res.data.data?.find((exam_version: any) => exam_version.exam_id == id);
          if (!foundExamVersion || foundExamVersion == undefined) {
            foundExamVersion = { exam_id: id, versions: "1" }
          }
          setExamVersions(foundExamVersion.versions.split(","))
          let tempAllExamVersions: any = [...allExamVersions]
          tempAllExamVersions.push(foundExamVersion)
          setAllExamVersions(tempAllExamVersions)
        }).catch((err) => {
          console.log(err)
        })
      }

    }
  }
  const copyAssessmentHandelr = (id: any, version: any) => {
    navigate.push({
      pathname: "/assessmentcomponent",
      search: `?id=${id}&copy=1&v=${version}`
    });
  };

  const loading = () => {
    Swal.fire({
      html: '<div style="color:blue;"> Loading....... </div>',
      showConfirmButton: false,
    });
  };
  const deleteAssessmentHandelr = async (id: any, version: any) => {
    let swal_confirmed = false
    const swal_res = await Swal.fire(
      {
        title: "Are you sure you want to delete this?",
        text: "Please note that this action cannot be undone.?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
      }

    ).then(
      (res: any) => {
        if (res.isConfirmed) {
          swal_confirmed = true
        }
      }
    )

    if (swal_confirmed) {
      loading()

      axios.post(BASE_URL + "admin/deleteexam.php", { id: id, version }).then((res) => {
        setExamVersionsModal(false)
        setAllExamVersions([]);
        setExamVersions([])

        Swal.fire("Exam version was deleted successfully ", "Please note that the questions of selected version will not be deleted and exam will be deleted if this version is the last one", "success")
        if (res.data.data == true) {

          const tmp1 = [...assessments]
          const tmp = [...tmp1.filter((assessment_X: any) => assessment_X.id != id)]

          setAssessments(tmp)
        }
        // $("#dataTable").DataTable({
        //   order: [[4, 'desc']]
        // });
      }).catch((err) => {
        Swal.fire(err.response.data.msg, "", "error");
      })
    }


  };

  const getAssessmentGroups = (id: any, group_id: any) => {
    Swal.showLoading();
    setModal(true)
    setGroup_id(group_id)
    setSelectedAssessment_id(id)
  };

  const PublishAssessment = (Students: any) => {
    Swal.showLoading();
    axios.post(BASE_URL + `${isRetake ? "admin/retake_assessment.php" : "admin/publishassessment.php"}`, { id: selectedAssessment_id, students: Students })
      .then((res) => {
        // setAssessments(res.data.data)
        const tmp1: any = [...assessments]
        tmp1.find((x: any) => x.id === selectedAssessment_id).published = '1'
        const tmp: any = [...tmp1]
        setAssessments(tmp)
        setIsRetake(false)
        Swal.close();
        Swal.fire({
          title: 'Published Successfully',
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: true
        })
      }).catch((err) => {
        Swal.fire(err.response.data.msg, "", "error");
      })

  }
  const pullAssessment = (id: any, version: any) => {
    Swal.showLoading();
    axios
      .post(
        BASE_URL + "admin/pullassessment.php",
        { id: id, version: version }
      )
      .then((res) => {
        const tmp1: any = [...assessments]
        if (res.data.data == true) {

          tmp1.find((x: any) => x.id === id).published = '0'
          const tmp: any = [...tmp1]
          setAssessments(tmp)
        }
        setExamVersionsModal(false)
        Swal.fire("Exam pullled successfully", " ", "success")
      }).catch((err) => {
        Swal.fire(err.response.data.msg, "", "error");
      })
  };
  const viewAssessment = (id: any, version: any) => {
    navigate.push({
      pathname: "/assessmentViewComponent",
      search: `?id=${id}&exammode=examMode&v=${version}`,
    });
  };
  const scoresDistribution = (id: any, version: any) => {
    navigate.push({
      pathname: `/dashboard/normaldistribution/${id}/${version}`,

    });
  };
  const analysis = (id: any, version: any) => {

    navigate.push({
      pathname: `/dashboard/analysis/${id}/${version}`,

    });
  };
  const viewAssessmentAsStudent = (id: any, version: any) => {
    navigate.push({
      pathname: "/veiwexamasstudent",
      search: `?id=${id}&v=${version}`
    });
  };

  const handleModalClose = () => {
    setModal(false)
  }
  const addAssessmentHandler = () => {
    dispatch(AssessmentActionCreator.resetAssessment())
    navigate.push("/assessmentcomponent");
  };
  function makeid2(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const ExportToCsv = (assessment_id: any, version: any) => {
    Swal.showLoading();
    let assessment: any = assessments?.find((a: any) => a.id == assessment_id)
    let assessment_name = `id${assessment_id}-version${version}`
    if (assessment) {
      assessment_name = `${assessment.name} - version ${version}`
    }
    axios.get(BASE_URL + `admin/analysis/exporttocsv.php?id=${assessment_id}&version=${version}`).then((res) => {
      Swal.close();

      const blob = new Blob([res.data], { type: 'text/csv' });

      // Create a link element
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${assessment_name}.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);


    }).catch((err) => {
      Swal.fire("Please check your internet and try again ", "", "error");
    })
  }

  const HandleNotify = (id: any) => {
    Swal.fire({
      title: "Send notification?",
      text: "Are you sure to send mail to pending assessment?",
      icon: 'warning',
      confirmButtonText: 'Okay',
    }).then((x) => {
      if (x.isConfirmed) {
        loading()
        axios.get(BASE_URL + `admin/notify.php?exam_id=${id}`).then((res) => {
          Swal.fire({
            title: "success",
            text: "Emails were sent successfully",
            icon: 'success',
            confirmButtonText: 'Okay',
          });
        }).catch((err) => {
          Swal.fire(err.response.data.msg, "", "error");
        })
      }

    })
  }

  const handleSubmitAnotherVersion = (type: any) => {

    Swal.showLoading();

    let version = +examVersions + 1;
    if (type == "manually") {
      editAssessmentHandelr(selectedAssessment_id, version);

    } else {
      axios.get(`${BASE_URL}/admin/get_ai_questions.php?exam_id=${selectedAssessment_id}&version=${version}`).then((res) => {
        let exam_version_object = {
          exam_id: selectedAssessment_id,
          version: version,
          questions: res.data.data
        }
        localStorage.setItem("exam_questions", JSON.stringify(exam_version_object))
        editAssessmentHandelr(selectedAssessment_id, version, true);

      })

    }

  }
  const editExamVersion = (e: any) => {
    e.preventDefault();
    editAssessmentHandelr(selectedAssessment_id, selectedVersion.current.value);

  }
  const handleGradeLevelChange = (e: any) => {
    let value = e.target.value
    setFilterData((prev: any) => ({ ...prev, grade_id: value }))

    axios.get(`${BASE_URL}admin/get_grade_groups.php?grade_id=${value}&year_id=${filterData.year_id}`).then((res: any) => {
      setgroups(res.data.data)


    })
  }
  const handleGroupChange = (e: any) => {
    let value = e.value
    axios.get(`${BASE_URL}admin/get_group_courses.php?group_id=${value}&year_id=${filterData.year_id}`).then((res: any) => {
      setCourses(res.data.data)
      setFilterData((prev: any) => ({ ...prev, group_id: value }))

    })
  }
  const NavigateToUpdateModelAnswers = (id: any, version: any, isAi = false) => {
    navigate.push({
      pathname: "/UpdateModelAnswers",
      search: `?id=${id}&v=${version}&isAi=${isAi}`
    });
  };
  const handleNextPage = () => {
    getExams({ ...pagination, pageNumber: pagination.pageNumber + 1, ...filterData });
    setPagination((prev: any) => ({ ...prev, pageNumber: pagination.pageNumber + 1 }))
  }
  const handlePreviousPage = () => {
    getExams({ ...pagination, pageNumber: pagination.pageNumber - 1, ...filterData });
    setPagination((prev: any) => ({ ...prev, pageNumber: pagination.pageNumber - 1 }))
  }
  const handleSelectModalSubmit = (e: any) => {
    e.preventDefault();
    console.log(selectedVersion.current.value)
    if (action == "edit") {
      // check if there is student exam
      Swal.showLoading();
      axios.get(`${BASE_URL}admin/check_if_can_edit_exam_version.php?exam_id=${selectedAssessment_id}&version=${selectedVersion.current.value}`)
        .then((res: any) => {
          if (res.data.data == true) {

            editAssessmentHandelr(selectedAssessment_id, selectedVersion.current.value);
          } else {
            Swal.fire("You can't edit exam as it was published before", "", "warning")
          }
        })

    } else if (action == "copy") {
      copyAssessmentHandelr(selectedAssessment_id, selectedVersion.current.value)
    } else if (action == "studentview") {
      viewAssessmentAsStudent(selectedAssessment_id, selectedVersion.current.value);
    } else if (action == "preview") {
      viewAssessment(selectedAssessment_id, selectedVersion.current.value);
    } else if (action == "scoresdist") {
      scoresDistribution(selectedAssessment_id, selectedVersion.current.value)
    } else if (action == "analysis") {
      analysis(selectedAssessment_id, selectedVersion.current.value)

    } else if (action == "pull") {
      pullAssessment(selectedAssessment_id, selectedVersion.current.value);
    } else if (action == "delete") {
      deleteAssessmentHandelr(selectedAssessment_id, selectedVersion.current.value);
    } else if (action == "updateModelAnswer") {
      NavigateToUpdateModelAnswers(selectedAssessment_id, selectedVersion.current.value);
    } else if (action == "csv") {
      ExportToCsv(selectedAssessment_id, selectedVersion.current.value)
    }

  }

  const handleApplyFilter = () => {
    getExams({ ...pagination, pageNumber: 1, ...filterData });
    setPagination((prev: any) => ({ ...prev, pageNumber: 1 }))


  }

  return (
    <div>
      {role == "Admin" || role == "Teacher" ? (
        <button
          className="btn bg-info text-white mb-2"
          onClick={addAssessmentHandler}
        >
          Add Assessment
        </button>
      ) : null}
      {
        <div className="table-responsive">

          <div>
            <h4>Filter</h4>

            <div className="row">
              <div className="col-md-3 ">
                Academic year
                <select className="form-control" value={filterData.year_id} onChange={(e: any) => {
                  let value = e.target.value
                  setFilterData((prev: any) => ({ ...prev, year_id: value }))
                  axios.get(`${BASE_URL}admin/get_year_quarters.php?year_id=${value}`).then((res:any)=>{
                    setGradingPeriods(res.data.data)
                  })

                }}>
                  <option value=""> Select</option>
                  {years?.map((year: any , index :any) => <option key={index} value={year.id}> {year.academicyear}</option>)}
                </select>
              </div>
              <div className="col-md-3 ">
                Grading period
                <select className="form-control" value={filterData.grading_period_id} onChange={(e: any) => {
                  let value = e.target.value
                  setFilterData((prev: any) => ({ ...prev, grading_period_id: value }))

                }}>
                  <option value=""> Select</option>
                  {grading_periods?.map((period: any , index :any) => <option key={index} value={period.id}> {period.name}</option>)}
                </select>
              </div>
              <div className="col-md-3 ">
                Grade level
                <select className="form-control" onChange={(e) => { handleGradeLevelChange(e) }} >
                  <option value=""> Select</option>
                  {grades?.map((grade: any, index :any) => <option key={index} value={grade.id}> {grade.grade}</option>)}

                </select>
              </div>
              <div className="col-md-3 ">
                Group
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      color: 'black', // Color of text inside the control area (selected option)
                    }),


                    option: (provided, state) => ({
                      ...provided,
                      color: 'black', // Color of the option text
                    }),
                  }}
                  options={groups?.map((group: any) => {
                    return { value: group.id, label: group.name };
                  })}

                  onChange={(e: any) => {
                    handleGroupChange(e)


                  }}



                />
              </div>
              <div className="col-md-3 ">
                Course
                <Select
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      color: 'black', // Color of text inside the control area (selected option)
                    }),


                    option: (provided, state) => ({
                      ...provided,
                      color: 'black', // Color of the option text
                    }),
                  }}
                  options={courses?.map((course: any) => {
                    return { value: course.coursegroup_id, label: course.name };
                  })}

                  onChange={(e: any) => {
                    setFilterData((prev: any) => ({ ...prev, coursegroup_id: e.value }))



                  }}



                />
              </div>

              <div className="col-md-3 ">
                From :
                <input type="date" className="form-control" onChange={(e: any) => {
                  let value = e.target.value
                  setFilterData((prev: any) => ({ ...prev, fromDate: value }))
                }} />
              </div>
              <div className="col-md-3 ">
                To :
                <input type="date" className="form-control" onChange={(e: any) => {
                  let value = e.target.value
                  setFilterData((prev: any) => ({ ...prev, toDate: value }))
                }} />
              </div>
              <div className="text-center col-12 mt-3"><button className="btn btn-success" onClick={() => {
                handleApplyFilter()
              }}>Apply filter</button></div>
            </div>
          </div>
          <div className="d-flex justify-content-between">

            <div>

              <label htmlFor="rowsPerPage">Rows per page:</label>
              <select id="rowsPerPage" className="form-select d-inline-block w-auto" onChange={(e: any) => {
                let value = e.target.value;
                getExams({ ...pagination, rowsPerPage: value, ...filterData });
                setPagination((prev: any) => ({ ...prev, rowsPerPage: value }))
              }}>
                <option value="5" selected={pagination.rowsPerPage == 5}>5</option>
                <option value="10" selected={pagination.rowsPerPage == 10}>10</option>
                <option value="20" selected={pagination.rowsPerPage == 20}>20</option>
                <option value="50" selected={pagination.rowsPerPage == 50}>50</option>
                <option value="100" selected={pagination.rowsPerPage == 100}>100</option>
              </select>

            </div>

          </div>

          <table id="dataTable" className="table table-borderd">
            <thead>
              <tr>
                <th>Course name </th>
                <th>Grade Level</th>
                <th>Group name</th>
                <th>Assessment</th>
                <th>Start date</th>

                <th>Published</th>
                <th>Statistics </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {assessments
                ? assessments.map((assessment: any, index: any) => {

                  return (
                    <tr key={assessment.id}>
                      <td>{assessment.crsname}</td>
                      <td>{assessment.gradelevel}</td>
                      <td>{assessment?.group}</td>
                      <td>{assessment?.name}</td>

                      <td>{assessment.startdate}</td>
                      <td>{assessment.published == "1" ? "Yes " : "No"}</td>
                      {/* 
                    <td>
                    {
                      Object.keys(assessment.Statistics).map((k, index) => <div key={index}>{k + " : " + assessment.Statistics[k]}</div>)
                    }
                  </td> */}

                      <td>
                        <div>Graded : {assessment?.GradedCount}</div>
                        <div>Submitted : {assessment?.SubmittedCount}</div>
                        <div>Started : {assessment?.StartedCount}</div>
                        <div>Pending : {assessment?.PendingCount}</div>
                      </td>

                      <td>


                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            getExamVersions(assessment.id, "delete")
                            setExamVersionsModal(true)

                          }}
                          title="Delete Assessment"
                        >
                          <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
                        </button>


                        {" "}
                        {

                          role !== "Admin" ? null :
                            <>
                              <button key="4" className="btn bg-info text-white mb-2"
                                onClick={() => {
                                  getExamVersions(assessment.id, "scoresdist")
                                  setExamVersionsModal(true)
                                }}

                                title="Scores Distribution"
                              >
                                <i className="fas fa-chart-line"></i>

                              </button>{" "}
                            </>
                        }
                        {" "}
                        {

                          role !== "Admin" ? null :
                            <>
                              <button key="4" className="btn bg-info text-white mb-2"
                                onClick={() => {
                                  getExamVersions(assessment.id, "analysis")
                                  setExamVersionsModal(true)
                                }}

                                title="Analysis" >
                                <i className="fa-solid fa-chart-simple"></i>


                              </button>{" "}
                            </>
                        }
                        {

                          assessment.published != "1" ? null :
                            <>
                              <button key="4" className="btn bg-info text-white mb-2" onClick={() => {

                                getExamVersions(assessment.id, "csv")
                                setExamVersionsModal(true)
                              }} title="CSV" >
                                CSV
                              </button>{" "}
                            </>
                        }
                        {

                          assessment.published != "1" ? null :
                            <>
                              <button key="4" className="btn bg-info text-white mb-2" onClick={() => {

                                HandleNotify(assessment.id);
                              }} title="Send mail" >
                                Notify
                              </button>{" "}
                            </>
                        }
                        <NavLink key="4" className="btn bg-info text-white mb-2" to={{
                          pathname: `/dashboard/StudentsExams/${assessment.id}`
                        }}  >
                          Students answers
                        </NavLink>{" "}
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            getExamVersions(assessment.id, "copy")
                            setExamVersionsModal(true)
                          }}
                          title="Copy"
                        >
                          <i className="fa-solid fa-copy"></i>
                        </button>{" "}
                        {/* {
                          assessment.published != "1" ? ( */}
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            getExamVersions(assessment.id, "edit")
                            setExamVersionsModal(true)
                          }}
                          title="Edit"
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        {/* //   ) : null
                        // }{" "} */}

                        <span> </span>
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {

                            getExamVersions(assessment.id, "preview")
                            setExamVersionsModal(true)
                          }}
                        >
                          Preview
                        </button>{" "}
                        <span> </span>
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {

                            getExamVersions(assessment.id, "studentview")
                            setExamVersionsModal(true)
                          }}
                        >
                          Student View
                        </button>{" "}
                        <span> </span>
                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {
                            getAssessmentGroups(assessment.id, assessment.coursegroup_id);
                            getExamVersions(assessment.id, "publish");

                          }}
                        >
                          Publish
                        </button>{" "}

                        <button
                          className="btn bg-info text-white mb-2"
                          onClick={() => {

                            getExamVersions(assessment.id, "pull");
                            setExamVersionsModal(true)

                          }}
                        >
                          Pull
                        </button>


                        {assessment.published == "1" ? (
                          <button
                            className="btn bg-info text-white mb-2 ml-1"
                            onClick={() => {
                              setIsRetake(true)
                              getAssessmentGroups(assessment.id, assessment.coursegroup_id);
                              getExamVersions(assessment.id, "retake")

                            }}
                          >
                            Retake
                          </button>
                        ) : null}

                        <button
                          className="btn bg-info text-white mb-2 mx-1"
                          onClick={() => {

                            setAnotherVersionModal(true)

                            getExamVersions(assessment.id, "createanotherversion")

                          }}
                        >
                          Create another version
                        </button>
                        <button
                          className="btn bg-info text-white mb-2 mx-1"
                          onClick={() => {

                            setExamVersionsModal(true)

                            getExamVersions(assessment.id, "updateModelAnswer")

                          }}
                        >
                          Update model answers
                        </button>

                      </td>
                    </tr>
                  );
                })
                : " "}
            </tbody>
          </table>
          <div className="d-flex justify-content-between">
            <div>


              <h6>Showing {examsCount > 0 ? ((+pagination.pageNumber - 1) * +pagination.rowsPerPage) + 1 : 0} to  {((((+pagination.pageNumber - 1) * +pagination.rowsPerPage) + 1) + +assessments?.length - 1) < examsCount ? (((+pagination.pageNumber - 1) * +pagination.rowsPerPage) + 1) + +assessments?.length - 1 : examsCount} of {examsCount} entries</h6>
            </div>

            <nav>
              <ul className="pagination">
                <li className={`page-item ${pagination.pageNumber == 1 ? "disabled" : ""} `} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => { handlePreviousPage() }} id="prevPage">Previous</a></li>
                <li className="page-item"><span className="page-link" id="pageNumber">{pagination.pageNumber}</span></li>
                <li className={`page-item ${pagination.pageNumber >= examsCount / pagination.rowsPerPage ? "disabled" : ""} `} style={{ cursor: "pointer" }}><a className="page-link" onClick={() => { handleNextPage() }} id="nextPage">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>
      }

      {modal &&
        <PublishAssessmentModal examVersions={examVersions} modalOpen={modal} isRetake={isRetake} setIsRetake={() => setIsRetake(false)} selectedAssessment_id={selectedAssessment_id} group_id={group_id} PublishAssessment={PublishAssessment} handleModalClose={handleModalClose} />
      }

      {/* // Create a new version modal  */}
      <Modal
        classNames={{
          modal: "customModal_2"
        }}
        open={anotherVersionModal}
        onClose={() => { setAnotherVersionModal(false); }}
        center
      >

        <button className="btn btn-success m-5" onClick={() => { handleSubmitAnotherVersion("manually") }}>Create an Assessment Manually</button>
        <button className="btn btn-primary m-5" onClick={() => { handleSubmitAnotherVersion("ai") }}>Create an Assessment Using AI</button>




      </Modal>
      {/* //select version to edit modal  */}
      <Modal
        classNames={{
          modal: "customModal_2"
        }}
        open={examVersionsModal}
        onClose={() => { setExamVersionsModal(false); }}
        center
      >
        <form onSubmit={(e) => { handleSelectModalSubmit(e) }}>
          <label className="mt-5">Select Version </label>
          <select className="form-control" aria-label="Default select example" required ref={selectedVersion}>
            <option value="" disabled selected >Select</option>
            {
              examVersions?.map((value) => {
                return <option key={value} value={value}>{`Version ${value}`}</option>
              })
            }
          </select>
          <div className="text-center mt-5">
            <button type="submit" className="btn btn-primary">Submit</button>
          </div>
        </form>
      </Modal>
    </div>
  )
}


export default Assessments